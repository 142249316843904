<template>
  <g :fill="color" fill-rule="nonzero">
    <path
      d="M13.81 9.583a7.245 7.245 0 0 1-.38.138c-.317.108-.68.216-1.081.317-2.2.554-4.5.665-6.634.061a9.558 9.558 0 0 1-.451-.14C3.865 9.488 2.548 8.68 1.328 7.622a.5.5 0 1 0-.656.756c1.313 1.138 2.74 2.012 4.272 2.529.165.055.33.107.499.154 2.326.66 4.795.54 7.15-.053.428-.108.816-.224 1.158-.34.208-.07.357-.126.439-.16a.5.5 0 0 0-.38-.925zM21.81 10.508c.082.034.23.09.439.16.342.116.73.232 1.157.34 2.356.593 4.826.712 7.153.053.167-.048.332-.099.496-.154 1.532-.517 2.958-1.39 4.272-2.53a.5.5 0 1 0-.654-.755c-1.223 1.059-2.54 1.866-3.937 2.337a9.56 9.56 0 0 1-.45.14c-2.134.604-4.435.493-6.635-.061-.401-.101-.764-.21-1.081-.317a7.241 7.241 0 0 1-.38-.138.5.5 0 1 0-.38.925z"
    />
    <path
      d="M18.275 4.133h-.55l-2.261-2.274C13.766.14 10.92.038 9.092 1.633c-.086.075-.166.15-.243.23-1.477 1.485-1.766 3.699-.746 5.474.047.08.097.159.15.235a6.808 6.808 0 0 0 3.779 2.697c.736.2 1.471.277 2.204.204a.5.5 0 0 0-.1-.995 5.07 5.07 0 0 1-1.84-.174 5.81 5.81 0 0 1-3.221-2.302 2.483 2.483 0 0 1-.107-.167c-.794-1.38-.569-3.102.593-4.27.061-.063.122-.12.189-.178 1.43-1.249 3.676-1.17 5.003.176l2.41 2.422a.5.5 0 0 0 .355.148h.965a.5.5 0 0 0 .355-.148l2.408-2.42c1.328-1.347 3.574-1.427 5.004-.178.072.062.13.117.183.172 1.168 1.174 1.393 2.895.597 4.28-.026.046-.061.1-.103.16a5.806 5.806 0 0 1-3.223 2.306 5.06 5.06 0 0 1-1.84.173.5.5 0 0 0-.099.995 6.052 6.052 0 0 0 2.202-.204 6.804 6.804 0 0 0 3.781-2.7c.057-.08.108-.16.15-.234 1.02-1.773.73-3.987-.75-5.476a3.77 3.77 0 0 0-.24-.226C25.08.038 22.234.14 20.536 1.861l-2.26 2.272z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
